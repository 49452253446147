/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiTypography from "components/VuiTypography";

function Reviews() {
  return (
    <Card sx={{ height: "100%" }}>
      <VuiBox mb="24px">
        <VuiTypography variant="lg" fontWeight="bold" color="white">
          Flotte de véhicules
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <VuiBox component="li" w="100%" py={1} mb={{sm: "14px", xl: "28px"}}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="10px">
              <VuiTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Sans défauts
              </VuiTypography>
              <VuiTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                95%
              </VuiTypography>
            </VuiBox>
            <VuiProgress color="info" value={80} sx={{ background: "#2D2E5F" }} />
          </VuiBox>
          <VuiBox component="li" w="100%" py={1} mb={{sm: "14px", xl: "28px"}}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="10px">
              <VuiTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Avec défauts
              </VuiTypography>
              <VuiTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                5%
              </VuiTypography>
            </VuiBox>
            <VuiProgress color="error" value={3} sx={{ background: "#2D2E5F" }} />
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default Reviews;
