import { useState, useRef } from "react";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

import profile2 from "assets/images/profile-2.png";
import Icon from "@mui/material/Icon";

import { Camera } from "react-camera-pro";

function PhotoItem(props) {
  const [captureMode, setCaptureMode] = useState(false);
  const camera = useRef(null);
  const handleCamera = function() {
    if (captureMode === false) {
      setCaptureMode(true);
    } else {
      props.setImage(camera.current.takePhoto());
      setCaptureMode(false);
    }
    return;
  }
  return (
    <>
      <VuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        color="white"
      >
        {props.label}
        <VuiTypography variant="caption" fontWeight="regular" color="text">
          {props.subLabel}
        </VuiTypography>
      </VuiTypography>
      <VuiBox position="relative" height="max-content" mx="auto">
        { (captureMode) ? (
          <Camera ref={camera} facingMode="environment" />
        ) : ( <></> ) }
        <VuiBox
          component="img"
          src={profile2}
          mb="8px"
          borderRadius="15px"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xl")]: {
              height: "200px",
            },
          })}
        />
        <VuiBox position="relative" right={0} top={-50} mr={-1} mb={-1}>
          <VuiButton
            variant="contained"
            color="success"
            sx={{ borderRadius: "12px", width: "35px", height: "35px" }}
            iconOnly
          >
            <Icon>check</Icon>
          </VuiButton>
        </VuiBox>
        <VuiBox position="relative" right={-40} top={-81} mr={-1} mb={-1}>
          <VuiButton
            variant="contained"
            color="info"
            sx={{ borderRadius: "12px", width: "35px", height: "35px" }}
            iconOnly
            onClick={handleCamera}
          >
            <Icon>edit</Icon>
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </>
  );
}

export default PhotoItem;