/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiBadgeDot from "components/VuiBadgeDot";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";

import { FaCarSide } from "react-icons/fa";
import { MdModeOfTravel } from "react-icons/md";

// Reports page components
import Reviews from "layouts/applications/vehicle/components/Reviews";

import avatar from "assets/images/avatar-simmmple.png";

function VehicleReports() {

  // ComplexStatisticsCard dropdown menu state
  const [usersActiveMenu, setUsersActiveMenu] = useState(null);
  const [clickEventsMenu, setClickEventsMenu] = useState(null);

  // ComplexStatisticsCard dropdown menu handlers
  const openUsersActiveMenu = (event) => setUsersActiveMenu(event.currentTarget);
  const closeUsersActiveMenu = () => setUsersActiveMenu(null);
  const openClickEventsMenu = (event) => setClickEventsMenu(event.currentTarget);
  const closeClickEventsMenu = () => setClickEventsMenu(null);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Autre action</MenuItem>
      <MenuItem onClick={close}>Encore une autre action</MenuItem>
    </Menu>
  );

  const reports = {
    columns: [
      { name: "nom", align: "left" },
      { name: "immatriculation", align: "left" },
      { name: "conducteur", align: "left" },
      { name: "statut", align: "left" },
      { name: "défaut ampoules", align: "center" },
      { name: "défaut rampe", align: "center" },
      { name: "défaut radio", align: "center" },
    ],
  
    rows: [
      {
        nom: [avatar, "DSIR 1"],
        immatriculation: "XX-XXX-XX",
        conducteur: "GND MARTIN Pierre",
        statut: (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="OK" />
          </VuiBox>
        ),
        "défaut ampoules": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut rampe": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut radio": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        hasBorder: true,
      },
      {
        nom: [avatar, "DSIR 2"],
        immatriculation: "XX-XXX-XX",
        conducteur: "GND MARTIN Pierre",
        statut: (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="error" size="xs" badgeContent="KO" />
          </VuiBox>
        ),
        "défaut ampoules": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="error" size="xs" badgeContent="KO" />
          </VuiBox>
        ),
        "défaut rampe": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut radio": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        hasBorder: true,
      },
      {
        nom: [avatar, "DSIR 3"],
        immatriculation: "XX-XXX-XX",
        conducteur: "GND MARTIN Pierre",
        statut: (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="OK" />
          </VuiBox>
        ),
        "défaut ampoules": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut rampe": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut radio": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        hasBorder: true,
      },
      {
        nom: [avatar, "DSIR 4"],
        immatriculation: "XX-XXX-XX",
        conducteur: "GND MARTIN Pierre",
        statut: (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="error" size="xs" badgeContent="KO" />
          </VuiBox>
        ),
        "défaut ampoules": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut rampe": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="success" size="xs" badgeContent="RAS" />
          </VuiBox>
        ),
        "défaut radio": (
          <VuiBox ml={-1.325}>
            <VuiBadgeDot color="error" size="xs" badgeContent="KO" />
          </VuiBox>
        ),
        hasBorder: true,
      },
    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <ComplexStatisticsCard
                    icon={<FaCarSide size="25px" />}
                    count={{ number: 27, label: "véhicules" }}
                    percentage=""
                    dropdown={{
                      action: openUsersActiveMenu,
                      menu: renderMenu(usersActiveMenu, closeUsersActiveMenu),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ComplexStatisticsCard
                    icon={<MdModeOfTravel size="25px" />}
                    count={{ number: 357, label: "patrouilles" }}
                    percentage="+124%"
                    dropdown={{
                      action: openClickEventsMenu,
                      menu: renderMenu(clickEventsMenu, closeClickEventsMenu),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Reviews />
            </Grid>
          </Grid>
        </VuiBox>
        <Table columns={reports.columns} rows={reports.rows} />
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VehicleReports;
