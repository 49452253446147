/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState } from "react";
import axios from 'axios';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Vehicle page components
import Selection from "layouts/applications/vehicle/components/Selection";
import Exterior from "layouts/applications/vehicle/components/Exterior";
import Interior from "layouts/applications/vehicle/components/Interior";
import Equipment from "layouts/applications/vehicle/components/Equipment";

function getSteps() {
  return ["Sélection", "Extérieur", "Intérieur", "Equipement"];
}

function Vehicle({ type }) {
  const [activeStep, setActiveStep] = useState(type.toString() === "collection" ? 0 : 1);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleFinish = async () => {
    try {
      const formData = new FormData();
      formData.append('vehicle', vehicle);
      formData.append('driver', driver);

      formData.append('frontPicture', frontPicture);
      formData.append('backPicture', backPicture);
      formData.append('leftPicture', leftPicture);
      formData.append('rightPicture', rightPicture);
      formData.append('exteriorDefects', exteriorDefects);
      formData.append('lightDefect', lightDefect);
      formData.append('lightDefectDetails', lightDefectDetails);

      formData.append('dashboardPicture', dashboardPicture);
      formData.append('mileage', mileage);
      formData.append('tankLevel', tankLevel);
      formData.append('interiorDefects', interiorDefects);

      formData.append('lightbarDefect', lightbarDefect);
      formData.append('radioDefect', radioDefect);
      formData.append('equipmentDefects', equipmentDefects);

      const API_PROTOCOL = process.env.API_PROTOCOL || 'http';
      const API_HOST = process.env.API_HOST || 'localhost';
      const API_PORT = process.env.API_PORT || '3001';
      const API_PREFIX = process.env.API_PREFIX || '';

      const response = await axios.post(`${API_PROTOCOL}://${API_HOST}:${API_PORT}/${API_PREFIX}vehicles/usage/collection`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(`Success: ${response.data.message}`);
    } catch (error) {
      console.log(`Error: ${error.response ? error.response.data.error : 'Unknown error'}`);
      console.log(error);
    }
  };

  const [vehicle, setVehicle] = useState(null);
  const [driver, setDriver] = useState(null);

  const [frontPicture, setFrontPicture] = useState(null);
  const [backPicture, setBackPicture] = useState(null);
  const [leftPicture, setLeftPicture] = useState(null);
  const [rightPicture, setRightPicture] = useState(null);
  const [exteriorDefects, setExteriorDefects] = useState(false);
  const [lightDefect, setLightDefect] = useState(false);
  const [lightDefectDetails, setLightDefectDetails] = useState("");

  const [dashboardPicture, setDashboardPicture] = useState(null);
  const [mileage, setMileage] = useState("");
  const [tankLevel, setTankLevel] = useState("");
  const [interiorDefects, setInteriorDefects] = useState("");

  const [lightbarDefect, setLightbarDefect] = useState(false);
  const [radioDefect, setRadioDefect] = useState(false);
  const [equipmentDefects, setEquipmentDefects] = useState("");

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Selection
            setVehicle={setVehicle}
            setDriver={setDriver}
          />;
      case 1:
        return <Exterior
            type={type}
            setFrontPicture={setFrontPicture}
            setBackPicture={setBackPicture}
            setLeftPicture={setLeftPicture}
            setRightPicture={setRightPicture}
            setExteriorDefects={setExteriorDefects}
            setLightDefect={setLightDefect}
            setLightDefectDetails={setLightDefectDetails}
          />;
      case 2:
        return <Interior
            setDashboardPicture={setDashboardPicture}
            setMileage={setMileage}
            setTankLevel={setTankLevel}
            setInteriorDefects={setInteriorDefects}
          />;
      case 3:
        return <Equipment
            type={type}
            setLightbarDefect={setLightbarDefect}
            setRadioDefect={setRadioDefect}
            setEquipmentDefects={setEquipmentDefects}
          />;
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <VuiBox mt={6} mb={1} textAlign="center">
              <VuiBox mb={1}>
                <VuiTypography variant="h3" fontWeight="bold" color="white">
                  { type.toString() === "collection" ? "Perception d'un véhicule" : "Réintégration d'un véhicule" }
                </VuiTypography>
              </VuiBox>
              <VuiTypography variant="button" fontWeight="regular" color="white">
                { type.toString() === "collection" ?
                  "Veuillez suivre les étapes pour percevoir un véhicule" :
                  "Veuillez suivre les étapes pour réintégrer le véhicule" }
              </VuiTypography>
            </VuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <VuiBox p={2}>
                <VuiBox>
                  {getStepContent(activeStep)}
                  <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <VuiBox />
                    ) : (
                      <VuiButton variant="gradient" color="light" onClick={handleBack}>
                        Retour
                      </VuiButton>
                    )}
                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={!isLastStep ? handleNext : handleFinish}
                    >
                      {isLastStep ? "Terminer" : "Suivant"}
                    </VuiButton>
                  </VuiBox>
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Vehicle;
