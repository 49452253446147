/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import axios from 'axios';
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

function Selection(props) {
  const [vehicles, setVehicles] = useState([]);
  
  const API_PROTOCOL = process.env.API_PROTOCOL || 'http';
  const API_HOST = process.env.API_HOST || 'localhost';
  const API_PORT = process.env.API_PORT || '3001';
  const API_PREFIX = process.env.API_PREFIX || '';

  const loadVehicles = async () => {
    try {
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}:${API_PORT}/${API_PREFIX}vehicles`, null, {});
      setVehicles(response.data);
    } catch (error) {
      console.log(`Error: ${error.response ? error.response.data.error : 'Unknown error'}`);
      console.log(error);
    }
  };
  if (vehicles.length === 0) {
    loadVehicles();
  }

  const displayVehicles = () => {
    return vehicles.map((vehicle) => {
      return <MenuItem value={vehicle._id}>{vehicle.name} - {vehicle.plate}</MenuItem>;
    });
  };

  const handleSetVehicle = (event) => props.setVehicle(event.target.value);
  const handleSetDriver = (event) => props.setDriver(event.target.value);

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="h5" fontWeight="regular" color="white">
            Sélectionnez le véhicule perçu
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="body2" fontWeight="regular" color="text">
          La perception du véhicule est à effectuer par le conducteur.
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                >
                  Choisissez un véhicule dans la liste
                </VuiTypography>
              </VuiBox>
              <Select input={<VuiInput />} placeholder="Choisissez un véhicule" onChange={handleSetVehicle}>
                {displayVehicles()}
              </Select>
            </VuiBox>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                >
                  Conducteur
                </VuiTypography>
              </VuiBox>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                <VuiInput placeholder="GRADE NOM Prénom" onChange={handleSetDriver} />
              </VuiBox>
              {/* <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                <VuiTypography
                    component="label"
                    variant="caption"
                    color="white"
                  >
                  Je suis le conducteur du véhicule
                </VuiTypography>&nbsp;&nbsp;
                <VuiSwitch color="info" defaultChecked />
              </VuiBox>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                <Select input={<VuiInput />} placeholder="Sélectionnez le conducteur" value={state} onChange={handleSetState}>
                  <MenuItem value="1">Militaire 1</MenuItem>
                  <MenuItem value="2">Militaire 2</MenuItem>
                  <MenuItem value="3">Militaire 3</MenuItem>
                  <MenuItem value="4">Militaire 4</MenuItem>
                </Select>
              </VuiBox> */}
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Selection;
