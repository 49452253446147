/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiEditor from "components/VuiEditor";
import VuiSwitch from "components/VuiSwitch";
import VuiInput from "components/VuiInput";

import PhotoItem from "./photoItem";

function Exterior(props) {

  const [sameAsCollection, setSameAsCollection] = useState(true);
  const handleSameAsCollection = (event) => setSameAsCollection(!event.target.checked);

  const handleExteriorDefects = (event) => props.setExteriorDefects(event);
  const handleLightDefect = (event) => props.setLightDefect(event.target.checked);
  const handleLightDefectDetails = (event) => props.setLightDefectDetails(event);

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Contrôle de l'état extérieur du véhicule
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Merci de prendre des photos de l'état extérieur du véhicule.
        </VuiTypography>
      </VuiBox>
      { (props.type === "reintegration") ? (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="white"
              >
                Etat extérieur du véhicule par rapport à la perception
              </VuiTypography>
            </VuiBox>
            
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <VuiTypography
                component="label"
                variant="caption"
                color="white"
              >
                Identique
              </VuiTypography>
              &nbsp;&nbsp;
              <VuiSwitch color="error" onChange={handleSameAsCollection} />
              &nbsp;&nbsp;
              <VuiTypography
                component="label"
                variant="caption"
                color="white"
              >
                Différent
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      ) : null }
      { ((props.type === "collection") || (sameAsCollection === false)) ? (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <PhotoItem label="Avant" setImage={props.setFrontPicture} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhotoItem label="Arrière" setImage={props.setBackPicture} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhotoItem label="Côté conducteur" setImage={props.setLeftPicture} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhotoItem label="Côté passager" setImage={props.setRightPicture} />
          </Grid>
          <Grid item xs={12} md={6}>
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
            >
              Précisez les défauts éventuels
            </VuiTypography>
            <VuiEditor onChange={handleExteriorDefects} modules={{ toolbar: false }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="white"
              >
                Ampoules
              </VuiTypography>
            </VuiBox>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <VuiTypography
                component="label"
                variant="caption"
                color="white"
              >
                Fonctionnelles
              </VuiTypography>
              &nbsp;&nbsp;
              <VuiSwitch color="error" onChange={handleLightDefect} />
              &nbsp;&nbsp;
              <VuiTypography
                component="label"
                variant="caption"
                color="white"
              >
                Défectueuses
              </VuiTypography>
              <VuiInput placeholder="Indiquez la ou les ampoules défectueuses" onChange={handleLightDefectDetails} />
            </VuiBox>
          </Grid>
        </Grid>
      ) : null }
    </VuiBox>
  );
}

export default Exterior;
