/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiEditor from "components/VuiEditor";
import VuiSwitch from "components/VuiSwitch";

function Interior(props) {

  const handleLightbarDefect = (event) => props.setLightbarDefect(event.target.checked);
  const handleRadioDefect = (event) => props.setRadioDefect(event.target.checked);
  const handleEquipmentDefects = (value) => props.setEquipmentDefects(value);

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Contrôle de l'équipement du véhicule
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Merci de rendre compte des défauts éventuels de l'équipement du véhicule.
        </VuiTypography>
      </VuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
            >
              Rampe (gyrophare et sirène)
            </VuiTypography>
          </VuiBox>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Fonctionnelle
            </VuiTypography>
            &nbsp;&nbsp;
            <VuiSwitch color="error" onChange={handleLightbarDefect} />
            &nbsp;&nbsp;
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Défectueuse
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
            >
              Radio
            </VuiTypography>
          </VuiBox>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Fonctionnelle
            </VuiTypography>
            &nbsp;&nbsp;
            <VuiSwitch color="error" onChange={handleRadioDefect} />
            &nbsp;&nbsp;
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Défectueuse
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item xs={12} md={12}>
          <VuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="white"
          >
            Précisez les défauts éventuels
          </VuiTypography>
          <VuiEditor onChange={handleEquipmentDefects} modules={{ toolbar: false }} />
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Interior;
